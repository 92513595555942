var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_vm._m(0),_c('br'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-6 col-md-6 d-flex align-items-stretch flex-column"},[_c('div',{staticClass:"card bg-light d-flex flex-fill"},[_c('div',{staticClass:"card-header text-muted border-bottom-0"}),_c('div',{staticClass:"card-body pt-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_vm._m(1),_vm._m(2),(_vm.ujian.qty_offline < 1)?_c('p',[_vm._v("Ujian tidak tersedia")]):_vm._e(),(_vm.ujian.qty_offline >= 1)?_c('p',[_vm._v(_vm._s(_vm.ujian.qty_offline)+" Ujian tersedia")]):_vm._e()])])]),_c('div',{staticClass:"card-footer"},[(_vm.ujian.qty_offline < 1)?_c('div',{staticClass:"text-right"}):_vm._e(),(_vm.ujian.qty_offline >= 1)?_c('div',{staticClass:"text-right"},[_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"to":{
                            name: 'Daftar Mapel Ujian',
                            params: { filter: { jenis: 'offline', id: _vm.ujian.id }},
                        }}},[_c('i',{staticClass:"fas fa-arrow-right"}),_vm._v(" Mulai ")])],1):_vm._e()])])]),_c('div',{staticClass:"col-12 col-sm-6 col-md-6 d-flex align-items-stretch flex-column"},[_c('div',{staticClass:"card bg-light d-flex flex-fill"},[_c('div',{staticClass:"card-header text-muted border-bottom-0"}),_c('div',{staticClass:"card-body pt-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_vm._m(3),_vm._m(4),(_vm.ujian.qty_online < 1)?_c('p',[_vm._v("Ujian tidak tersedia")]):_vm._e(),(_vm.ujian.qty_online >= 1)?_c('p',[_vm._v(_vm._s(_vm.ujian.qty_online)+" Ujian tersedia")]):_vm._e()])])]),_c('div',{staticClass:"card-footer"},[(_vm.ujian.qty_online < 1)?_c('div',{staticClass:"text-right"}):_vm._e(),(_vm.ujian.qty_online >= 1)?_c('div',{staticClass:"text-right"},[_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"to":{
                            name: 'Daftar Mapel Ujian',
                            params: { filter: { jenis: 'online', id: _vm.ujian.id }},
                        }}},[_c('i',{staticClass:"fas fa-arrow-right"}),_vm._v(" Mulai ")])],1):_vm._e()])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h4',[_vm._v("Pilih salah satu Jenis Ujian")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"lead"},[_c('b',[_vm._v("OFFLINE")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-muted text-sm"},[_c('b',[_vm._v("Ujian dengan Tatap Muka ")]),_vm._v(" di LBB Taruna Cendekia ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"lead"},[_c('b',[_vm._v("ONLINE")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-muted text-sm"},[_c('b',[_vm._v("Ujian Mandiri ")]),_vm._v(" di Rumah untuk evaluasi pembelajaran ")])
}]

export { render, staticRenderFns }